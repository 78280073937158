import React, { lazy } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import AdminRoutes from './admin/content.routes';

const presentation = [
	{
		path: '/',
		redirect: '/admin',
	},
];

const contents = [...presentation, ...AdminRoutes];

export default contents;
