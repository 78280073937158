import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import AdminRoutes from './admin/footer.routes';

const routes: RouteProps[] = [
	{
		path: '*',
		element: null,
	},
];

const footer = [...routes, ...AdminRoutes];

export default footer;
