import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import User from '../../../layout/User/User';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideFoot>
				<User collapsible asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
