import React from 'react';
import { RouteProps } from 'react-router-dom';
import CustomRoutes from '@this/impl/routes/footer.routes';
import { demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '/login', element: null },
	{ path: '/signup', element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: '*', element: <DefaultFooter /> },
	...CustomRoutes,
];

export default footers;
