import React, { lazy } from 'react';
import CustomRoutes from '@this/impl/routes/content.routes';
import { demoPagesMenu } from '../menu';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/Page404')),
};

const presentation = [
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
];

const contents = [...presentation, ...CustomRoutes];

export default contents;
