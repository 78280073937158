import React from 'react';
import { RouteProps } from 'react-router-dom';
import CustomRoutes from '@this/impl/routes/aside.routes';
import { demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: '/login', element: null },
	{ path: '/signup', element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: <DefaultAside /> },
	...CustomRoutes,
];

export default asides;
