import React, { FC, forwardRef, HTMLAttributes, ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AuthContext from '@this/template/contexts/authContext';
import { TIcons } from '../../type/icons-type';
import Item from './Item';
import List from './List';

interface INavigationLineProps {
	className?: string;
}

export const NavigationLine: FC<INavigationLineProps> = ({ className }) => {
	return <hr className={classNames('navigation-line', className)} />;
};

interface INavigationTitleProps extends HTMLAttributes<HTMLSpanElement> {
	className?: string;
	children: ReactNode;
}

export const NavigationTitle: FC<INavigationTitleProps> = ({ className, children, ...props }) => {
	return (
		<li className='navigation-item'>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<span className={classNames('navigation-title', className)} {...props}>
				{children}
			</span>
		</li>
	);
};

interface INavigationProps {
	horizontal?: boolean;
	menu: {
		[key: string]: {
			id?: string | number;
			text?: string;
			path?: string;
			icon?: TIcons;
			isDisable?: boolean;
			subMenu?: {
				[key: string]: {
					id?: string | number;
					text?: string;
					path?: string;
					icon?: TIcons;
					isDisable?: boolean;
				};
			} | null;
			privileges?: string[];
		};
	};
	id: string;
	className?: string;
}

// eslint-disable-next-line react/display-name
const Navigation = forwardRef<HTMLElement, INavigationProps>(
	({ menu, horizontal, id, className, ...props }, ref) => {
		const [activeItem, setActiveItem] = useState(undefined);
		const { hasPrivilege } = useContext(AuthContext);

		const { t } = useTranslation('menu');

		function fillMenu(
			data:
				| {
						id?: string | number;
						text?: string;
						path?: string;
						icon?: TIcons;
						isDisable?: boolean;
						subMenu?:
							| {
									id?: string | number;
									text?: string;
									path?: string;
									icon?: TIcons;
									isDisable?: boolean;
							  }[]
							| undefined;
				  }[]
				| any,
			parentId: string,
			rootId: string,
			isHorizontal: boolean | undefined,
			isMore: boolean | undefined,
		) {
			return Object.keys(data)
				.filter((item) => {
					const { privileges } = data[item];

					if (privileges) {
						if (!hasPrivilege(privileges)) return false;
					}

					return true;
				})
				.map((item) =>
					data[item].path ? (
						<Item
							key={data[item].id}
							rootId={rootId}
							id={data[item].id}
							title={data[item].text}
							icon={data[item].icon}
							to={data[item].path}
							parentId={parentId}
							isHorizontal={isHorizontal}
							setActiveItem={setActiveItem}
							activeItem={activeItem}
							notification={data[item].notification}
							hide={data[item].hide}>
							{!!data[item].subMenu &&
								fillMenu(
									data[item].subMenu,
									data[item].id,
									rootId,
									isHorizontal,
									undefined,
								)}
						</Item>
					) : (
						!isMore &&
						!isHorizontal && (
							<Item
								key={data[item].id}
								rootId={rootId}
								id={data[item].id}
								title={data[item].text}
								icon={data[item].icon}
								parentId={parentId}
								isHorizontal={isHorizontal}
								setActiveItem={setActiveItem}
								activeItem={activeItem}
								notification={data[item].notification}
								hide={data[item].hide}>
								{!!data[item].subMenu &&
									fillMenu(
										data[item].subMenu,
										data[item].id,
										rootId,
										isHorizontal,
										undefined,
									)}
							</Item>
							// <NavigationTitle key={data[item].id}>
							// 	{t(data[item].text) as ReactNode}
							// </NavigationTitle>
						)
					),
				);
		}

		return (
			// @ts-ignore
			// eslint-disable-next-line react/jsx-props-no-spreading
			<nav ref={ref} aria-label={id} className={className} {...props}>
				<List id={id} horizontal={horizontal}>
					{fillMenu(menu, id, id, horizontal, undefined)}
					{horizontal && (
						<Item
							rootId={`other-${id}`}
							title={t('More') as string}
							icon='MoreHoriz'
							isHorizontal
							isMore>
							{fillMenu(menu, `other-${id}`, `other-${id}`, false, true)}
						</Item>
					)}
				</List>
			</nav>
		);
	},
);

export default Navigation;
