import React from 'react';
import { RouteProps } from 'react-router-dom';
import CustomRoutes from '@this/impl/routes/header.routes';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '/login', element: null },
	{ path: '/signup', element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	...CustomRoutes,
];

export default headers;
