import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import MainHeader from '../pages/public/headers/main.header';
import AdminRoutes from './admin/header.routes';

const ADMIN = {
	HEADER: lazy(() => import('@this/impl/pages/admin/header')),
};

const presentation: RouteProps[] = [
	{
		path: '*',
		element: <ADMIN.HEADER />,
	},
];

const header = [...presentation, ...AdminRoutes];

export default header;
