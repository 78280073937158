import React from 'react';

interface LoadingProps {
	visible?: boolean;
	text?: string;
}

const Loading = (props: LoadingProps) => {
	const { visible, text } = props;
	return (
		visible && (
			<div className='text-center h-100 d-flex flex-row justify-content-center'>
				<div className='spinner-grow  text-primary align-self-center' role='status' />
				{text && <span className='ms-2 align-self-center'>{text}</span>}
			</div>
		)
	);
};

export default Loading;
