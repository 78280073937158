import React from 'react';
import config from '@this/impl/config';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultFooter = () => {
	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>&nbsp;</div>
					<div className='col-auto'>version {config.version}</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
