const config = {
	title:
		process.env.NODE_ENV === 'production'
			? window.env.REACT_APP_SITE_NAME
			: process.env.REACT_APP_SITE_NAME ?? 'OMS Console',
	version:
		process.env.NODE_ENV === 'production'
			? window.env.REACT_APP_VERSION
			: process.env.REACT_APP_VERSION ?? 'local',
	apiHost:
		process.env.NODE_ENV === 'production'
			? window.env.REACT_APP_API_HOST
			: process.env.REACT_APP_API_HOST ?? 'https://api.qa.oms.firstclose.com',
};

export default config;
