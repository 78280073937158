const productsMenu = {
	id: 'sampleCrudMenu',
	text: 'Products',
	icon: 'Dashboard',
	path: '/admin/products',
};

const ordersMenu = {
	id: 'ordersMenu',
	text: 'Orders',
	icon: 'Dashboard',
	path: '/admin/orders',
};

const lendersMenu = {
	id: 'lendersMenu',
	text: 'Lenders',
	icon: 'Group',
	path: '/admin/lenders',
};

const usersMenu = {
	id: 'usersMenu',
	text: 'Users',
	icon: 'Person',
	path: '/admin/users',
};

// eslint-disable-next-line import/prefer-default-export
export const adminMenu = {
	orders: ordersMenu,
	// pos: posConfigurationMenu,
	products: productsMenu,
	lenders: lendersMenu,
	users: usersMenu,
};
