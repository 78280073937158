import React, { FC } from 'react';
import PropTypes, { any } from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
	darkMode?: any;
}
const Logo: FC<ILogoProps> = ({ width, height, darkMode }) => {
	// eslint-disable-next-line jsx-a11y/alt-text
	return (
		<img
			style={{ width: '230px' }}
			src={darkMode ? '/logo.svg' : '/logo-light.svg'}
			alt='FirstClose'
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	darkMode: true,
};

export default Logo;
