import React, { ReactNode, useContext, useState } from 'react';
import { adminMenu } from '@this/impl/menus/admin';
import ThemeContext from '@this/template/contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '@this/template/layout/Aside/Aside';
import Navigation from '@this/template/layout/Navigation/Navigation';
import User from '@this/template/layout/User/User';
import { useTranslation } from 'react-i18next';

const AdminAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<Aside>
			<AsideHead>
				{asideStatus && <img style={{ width: '121px' }} src='/logo.svg' alt='Logo' />}
			</AsideHead>
			<AsideBody>
				<Navigation id='admin' menu={adminMenu} />
			</AsideBody>
			<AsideFoot>
				<User collapsible asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideFoot>
		</Aside>
	);
};

export default AdminAside;
