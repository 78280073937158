import path from 'path';
import React, { lazy } from 'react';

const ADMIN = {
	ORDERS: lazy(() => import('@this/impl/pages/admin/orders')),
	PRODUCTS: lazy(() => import('@this/impl/pages/admin/products')),
	LENDERS: lazy(() => import('@this/impl/pages/admin/lenders')),
	USERS: lazy(() => import('@this/impl/pages/admin/users')),
	DASHBOARD: lazy(() => import('@this/impl/pages/admin/welcome')),
};

const routes = [
	{
		path: '/admin',
		element: <ADMIN.DASHBOARD />,
	},
	{
		path: '/admin/orders',
		element: <ADMIN.ORDERS />,
	},
	{
		path: '/admin/products',
		element: <ADMIN.PRODUCTS />,
	},
	{
		path: '/admin/lenders',
		element: <ADMIN.LENDERS />,
	},
	{
		path: '/admin/users',
		element: <ADMIN.USERS />,
	},
];

export default routes;
